.custom-Label .ant-form-item-label {
  white-space: pre-line;
}
.custom-Label .ant-input-number {
  width: 100%;
}
/* 有时候表单项不需要，margin-bottom */
.form-item-no-margin .ant-form-item {
  margin-bottom: 0;
}

.tcc-cop-buy-main div.ant-typography {
  margin-bottom: 2px !important;
}
.tcc-cop-buy-main .cop-buy-body {
  overflow: scroll;
  height: 500px;
  position: relative;
}
.tcc-cop-buy-main .cop-buy-body .tea-layout {
  min-height: 0 !important;
}
.tcc-cop-buy-main .cop-buy-body .buy-loading {
  height: 100px;
  z-index: 1000;
  position: absolute;
  left: 48% !important;
  top: 28% !important;
}

.user-avatar-group.collapsed {
  padding-left: 10px;
}
.user-avatar-group.collapsed .ant-avatar {
  margin-left: -10px;
}
.user-avatar-group.collapsed .ant-avatar:hover {
  z-index: 1;
  transform: scale(1.3) translateY(-15%);
  transition: all 150ms ease;
}
.user-avatar-group.in-lined .ant-avatar:not(:last-child) {
  margin-right: 5px;
}

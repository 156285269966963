.component-page-header {
  margin: -20px -20px 0 !important;
  border-bottom: 1px solid #e8e8e8;
  padding-top: 12px !important;
}
.component-page-header .ant-page-header-heading .ant-page-header-back {
  margin: 8px 16px 8px 0;
}
.component-page-header .ant-page-header-heading .ant-page-header-heading-title {
  font-size: 16px;
  line-height: 32px;
}

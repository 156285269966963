.page-login {
  display: flex;
  justify-content: center;
}
.page-login .ant-card {
  background: transparent;
}
.page-login .login-page-inner {
  width: 680px;
  margin-top: 160px;
}
.page-login .login-page-inner .chooser-card {
  border: none;
}
.page-login .login-page-inner .chooser-card .login-title {
  text-align: center;
  color: #222;
}
.page-login .login-page-inner .chooser-card h3 {
  color: #444;
}
.page-login .login-page-inner .chooser-card .module-chooser,
.page-login .login-page-inner .chooser-card .company-chooser {
  width: 100%;
  position: absolute;
  transition: all 200ms ease;
}
.page-login .login-page-inner .chooser-card .module-chooser .navigate-back a,
.page-login .login-page-inner .chooser-card .company-chooser .navigate-back a {
  color: #999;
}
.page-login .login-page-inner .chooser-card .module-chooser .navigate-back a:hover,
.page-login .login-page-inner .chooser-card .company-chooser .navigate-back a:hover {
  color: #555;
}
.page-login .login-page-inner .chooser-card .module-chooser.hide {
  opacity: 0;
  pointer-events: none;
  transform: translateX(-100%);
}
.page-login .login-page-inner .chooser-card .company-chooser {
  opacity: 0;
  pointer-events: none;
  transform: translateX(100%);
}
.page-login .login-page-inner .chooser-card .company-chooser.visible {
  opacity: 1;
  pointer-events: visible;
  transform: translateX(0);
}
.page-login .login-page-inner .chooser-card > .ant-card-body {
  position: relative;
}
.page-login .login-page-inner .block-card {
  margin-right: -15px;
}
.page-login .login-page-inner .block-card > .ant-card-head {
  padding: 0;
  color: #999;
  font-weight: normal;
}
.page-login .login-page-inner .block-card > .ant-card-body {
  padding: 0;
  display: flex;
  flex-wrap: wrap;
}
.page-login .login-page-inner .block {
  width: 200px;
  flex-shrink: 0;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  background: #fff;
  border: 1px solid #dadfe6;
}
.page-login .login-page-inner .block .block-name {
  color: #444;
}
.page-login .login-page-inner .block .ant-card-body {
  padding: 12px;
}
.page-login .login-page-inner .block:hover {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.page-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.page-not-found {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.page-not-found .warning-image {
  margin-top: 200px;
  width: 269px;
  height: 241px;
}
.page-not-found h2 {
  margin-top: 20px;
  font-size: 18px;
  color: #444;
  font-weight: normal;
}
.page-not-found .small-tip {
  margin-top: 15px;
}
.page-not-found .small-tip .helper-link {
  margin: 0 5px;
}

/* antd已经升级到4.x,为了兼容旧的需要引入以下文件，见： https://github.com/ant-design/compatible */
.mt0 {
  margin-top: 0;
}
.ml0 {
  margin-left: 0;
}
.mr0 {
  margin-right: 0;
}
.mb0 {
  margin-bottom: 0;
}
.mt3 {
  margin-top: 3px;
}
.mt5 {
  margin-top: 5px;
}
.ml5 {
  margin-left: 5px;
}
.mr5 {
  margin-right: 5px;
}
.mb5 {
  margin-bottom: 5px;
}
.mlr5 {
  margin: 0 5px;
}
.mt10 {
  margin-top: 10px;
}
.ml10 {
  margin-left: 10px;
}
.mr10 {
  margin-right: 10px;
}
.mb10 {
  margin-bottom: 10px;
}
.mt15 {
  margin-top: 15px;
}
.ml15 {
  margin-left: 15px;
}
.mr15 {
  margin-right: 15px;
}
.mb15 {
  margin-bottom: 15px;
}
.mt20 {
  margin-top: 20px;
}
.ml20 {
  margin-left: 20px;
}
.mr20 {
  margin-right: 20px;
}
.mb20 {
  margin-bottom: 20px;
}
.ml30 {
  margin-left: 30px;
}
.ml50 {
  margin-left: 50px;
}
.pt5 {
  padding-top: 5px;
}
.pt10 {
  padding-top: 10px;
}
.pt15 {
  padding-top: 15px;
}
.text-primary {
  color: #1890ff;
}
.text-success {
  color: #52c41a;
}
.text-warning {
  color: #faad14;
}
.text-danger {
  color: #f5222d;
}
.text-grey {
  color: #ccc;
}
.text-muted {
  color: #bfbfbf;
}
.text-normal {
  color: #777;
}
.text-title {
  color: rgba(0, 0, 0, 0.85);
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}
.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
a.text-danger:hover {
  color: #ff4d4f;
}
a.text-danger:active {
  color: #cf1322;
}
.dashed-tip {
  cursor: pointer;
  border-bottom: 1px dashed #666;
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}
li {
  list-style: none;
}
.form-item-required::before {
  display: inline-block;
  margin-right: 4px;
  content: '*';
  font-family: SimSun;
  line-height: 1;
  font-size: 14px;
  color: #f5222d;
}
.link-disabled {
  color: #999;
  cursor: not-allowed;
}
.link-disabled:hover,
.link-disabled:active {
  color: #999;
}
.fl,
.pull-left {
  float: left;
}
.fr,
.pull-right {
  float: right;
}
.wordBreak {
  word-break: break-all;
  white-space: normal;
}
.card-green {
  background-color: #f6ffed;
  border: 1px solid #b7eb8f;
}
.card-red {
  background-color: #fff1f0;
  border: 1px solid #ffa39e;
}
.card-yellow {
  background-color: #fffbe6;
  border: 1px solid #ffe58f;
}
.card-gray {
  background: #f2f4f5;
  border: 1px solid #eee;
}
.flex-end {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
}
.no-label-text .ant-form-item-label > label {
  display: none;
}
.tpl-text {
  color: #f5222d;
  font-style: italic;
}
.clickable {
  cursor: pointer;
}
.fs18 {
  font-size: 18px;
}
.login-loading {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.ant-btn {
  line-height: 1.6;
  border-radius: 2px;
}
.ant-btn-circle,
.ant-btn-circle-outline {
  border-radius: 50%;
}
.ant-row .ant-select {
  width: 100%;
}
.ant-pagination-options .ant-select {
  width: auto;
}
.ant-descriptions .ant-descriptions-item-label {
  color: #a5acbb;
}
.ant-descriptions .ant-descriptions-item-content {
  color: rgba(0, 0, 0, 0.65);
}
.ant-descriptions .ant-descriptions-row {
  vertical-align: top;
}
.ant-descriptions .ant-descriptions-row .ant-descriptions-item-label {
  vertical-align: top;
}
.ant-descriptions.ant-descriptions-bordered .ant-descriptions-row {
  vertical-align: inherit;
}
.ant-descriptions.ant-descriptions-bordered .ant-descriptions-row .ant-descriptions-item-label {
  vertical-align: inherit;
}
.ant-card .ant-card-head {
  min-height: unset;
  border-bottom: none;
}
.ant-card .ant-card-head .ant-card-head-title {
  padding-bottom: 0;
}
.ant-modal .ant-modal-content .ant-modal-close-x {
  height: 38px;
}
.ant-modal .ant-modal-content .ant-modal-header {
  padding-bottom: 0;
  border-bottom: none;
}
.ant-modal .ant-modal-content .ant-modal-footer {
  padding-top: 0;
  padding-bottom: 16px;
  border-top: none;
}
.ant-modal .ant-modal-content .ant-modal-footer div {
  display: flex;
  justify-content: center;
}
.ant-modal .ant-modal-content .ant-modal-footer div button + button {
  margin-left: 20px;
}
.ant-page-header .ant-page-header-heading-extra > a {
  line-height: 32px;
}
.ant-page-header .ant-page-header-footer {
  margin-top: 0;
}
.ant-page-header .ant-page-header-footer .ant-tabs-bar .ant-tabs-nav .ant-tabs-tab {
  padding: 8px 14px;
  font-size: 14px;
  margin-right: 20px;
}
body {
  font-family: "SF Pro SC", "SF Pro Text", "SF Pro Icons", "PingFang SC", Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}
#root {
  background: #f0f2f5;
  display: flex;
}
#root .main-app {
  width: 100%;
  height: 100vh;
  padding: 20px;
  overflow: auto;
  position: relative;
}
#root .main-app .watermask {
  position: fixed;
  top: 0;
  left: 220px;
  right: 0;
  bottom: 0;
  z-index: 100;
  background-repeat: repeat;
  pointer-events: none;
  opacity: 0.13;
}
